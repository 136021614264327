.MUI5 .MuiTypography-h1 {
    font-size: 6rem !important;
    font-family: "Montserrat", "Roboto", "Helvetica", "Arial", sans-serif !important;
    font-weight: 500 !important;
    line-height: 1.167 !important;
    letter-spacing: -0.01562em !important;
}

.MUI5 .MuiTypography-h2 {
    font-size: 4.45rem !important;
    font-family: "Montserrat", "Roboto", "Helvetica", "Arial", sans-serif !important;
    font-weight: 500 !important;
    line-height: 1.2 !important;
    letter-spacing: -0.00833em !important;
}

.MUI5 .MuiTypography-h3 {
    font-size: 3.5rem !important;
    font-family: "Montserrat", "Roboto", "Helvetica", "Arial", sans-serif !important;
    font-weight: 500 !important;
    line-height: 1.167 !important;
    letter-spacing: 0em !important;
}

.MUI5 .MuiTypography-h4 {
    font-size: 2.625rem !important;
    font-family: "Montserrat", "Roboto", "Helvetica", "Arial", sans-serif !important;
    font-weight: 500 !important;
    line-height: 1.235 !important;
    letter-spacing: 0.00735em !important;
}

.MUI5 .MuiTypography-h5 {
    font-size: 2rem !important;
    font-family: "Montserrat", "Roboto", "Helvetica", "Arial", sans-serif !important;
    font-weight: 500 !important;
    line-height: 1.334 !important;
    letter-spacing: 0em !important;
}

.MUI5 .MuiTypography-h6 {
    font-size: 1.6rem !important;
    font-family: "Montserrat", "Roboto", "Helvetica", "Arial", sans-serif !important;
    font-weight: 500 !important;
    line-height: 1.6 !important;
    letter-spacing: 0.0075em !important;
}

.MUI5 .MuiTypography-body2 {
    font-size: 1.375rem !important;
    font-family: "Montserrat", "Roboto", "Helvetica", "Arial", sans-serif !important;
    font-weight: 400 !important;
    line-height: 1.43 !important;
    letter-spacing: 0.01071em !important;
}

.MUI5 .MuiTypography-body1 {
    font-size: 1.6rem !important;
    font-family: "Montserrat", "Roboto", "Helvetica", "Arial", sans-serif !important;
    font-weight: 400 !important;
    line-height: 1.5 !important;
    letter-spacing: 0.00938em !important;
}

.MUI5 .MuiTypography-subtitle1 {
    font-size: 1.3rem !important;
    font-family: "Montserrat", "Roboto", "Helvetica", "Arial", sans-serif !important;
    font-weight: 400 !important;
    line-height: 1.25 !important;
}

.MUI5.MuiDivider-root {
    height: 0;
}

.MuiTooltip-tooltip {
    font-size: 1.4rem !important;
}

.MUI5 .MUI5_BOLD {
    font-weight: 700 !important;
}