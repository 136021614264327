@import './normalize.css';

* {
    outline: none !important;
}

html {
    font-size: 62.5%;
    font-family: Montserrat, Roboto, Helvetica Neue, Arial, sans-serif;
    background-color: #0A373D;
    min-height: 100vh;
}

body {
    font-size: 14px;
    line-height: 1.4;
}

html,
body,
#root {
    width: 100%;
    position: relative;
}

h1,
.h1 {
    font-size: 24px;
}

h2,
.h2 {
    font-size: 20px;
}

h3,
.h3 {
    font-size: 16px;
}

h4,
.h4 {
    font-size: 15px;
}

h5,
.h5 {
    font-size: 13px;
}

h6,
.h6 {
    font-size: 12px;
}

.ps>.ps__rail-y,
.ps>.ps__rail-x {
    z-index: 99;
}

a[role=button] {
    text-decoration: none;
}

form label {
    z-index: 99;
}

#filter-input-wrapper button.MuiButton-contained {
    background-color: #245157;
    color: white;
}

.MuiDivider-root.MuiDivider-fullWidth {
    border: none;
    background-color: #AEB5BC;
    color: #AEB5BC;
    height: 1px;
}

@import './print.css';

@import './react-table.css';

@import './prism.css';

@import './tables.css';

@import './loading-screen.css';

@import './hubspot-chat.css';

@import './components.css';