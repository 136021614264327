.filepond--file-action-button {
    cursor: pointer;
}

.filepond--panel-root {
    border-radius: 6px !important;
    border: 1px solid #CFD4D9 !important;
    background-color: #F8F9FA !important;
}

.filepond--wrapper {
    width: 100% !important;
}

.filepond--drop-label {
    font-size: 16px !important;
    color: #878E95 !important;
}

.filepond--panel-top,
.filepond--panel-bottom {
    visibility: hidden !important;
}