.react-flow {
  background: white;
}
.react-flow__controls {
  display: flex;
  flex-direction: column;
  margin: 4rem;
  justify-content: space-between;
  box-shadow: none;
}

.react-flow__controls-button {
background: #FFFFFF;
border: 1px solid #E9ECEF;
border-radius: 4px;
width: 36px;
height: 36px;
}

@media (max-width: 440px) {
  .react-flow__controls-zoomin {
    margin-bottom: 16px;
  }
}

@media (max-width: 440px) {
  .react-flow__controls-zoomout {
    margin-bottom: 16px;
  }
}

.react-flow__handle {
  visibility: hidden;
}