.table {
  table-layout: auto;
  border-collapse: collapse;
}
.table-alternate tbody tr {
  border: none;
  background-color: white;
}
.table-alternate tbody tr:nth-of-type(even) {
  background-color: #F8F9FA;
}
.table-nobg {
  background-color: transparent !important;
}
