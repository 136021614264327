@import "styles-new/css/theme-color-definitions.css";

/**
ReactTable Overrides
 */
.ReactTable .rt-tbody .rt-td {
  display: flex;
  align-items: center;
}

.ReactTable .rt-thead.-header {
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.2);
}

.ReactTable .-pagination {
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  font-size: 14px;
  font-family: 'Lato';
  line-height: 22px;
  color: var(--grey800)
}

.ReactTable .-pagination .-previous .MuiIconButton-root,
.ReactTable .-pagination .-next .MuiIconButton-root {
  color: var(--grey700)
}

.ReactTable .-pagination .-previous .MuiIconButton-root.Mui-disabled,
.ReactTable .-pagination .-next .MuiIconButton-root.Mui-disabled {
  color: var(--grey500)
}

@media only screen and (max-width: 600px) {
  .ReactTable .-pagination {
    flex-wrap: wrap-reverse;
    justify-content: start;
  }

  .ReactTable .-pagination .-previous,
  .ReactTable .-pagination .-next {
    flex: 1 0 50%;
  }

  .ReactTable .-pagination .-center {
    flex: 0 1 100% !important;
  }
}

.ReactTable .rt-thead .rt-resizable-header-content {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.ReactTable .rt-thead.-filters .rt-th {
  padding: 16px 8px;
}

.ReactTable .rt-thead.-filters input,
.ReactTable .rt-thead.-filters select {
  border: none;
  background: initial;
  padding: 6px 0 7px 0;
}

.ReactTable .-pagination .-center {
  order: 0;
  justify-content: start;
  flex: 0 1 50%;
}

.ReactTable .-pagination .-previous,
.ReactTable .-pagination .-next {
  max-width: 64px;
}

.ReactTable .-pagination .-previous {
  order: 1;
}

.ReactTable .-pagination .-next {
  order: 2;
}

/* .ReactTable .-next {
    margin-right: 6rem;
} */


/* Importd from the old react-table */


.ReactTable {
  font-family: Montserrat, Roboto, sans-serif;
}

.ReactTable .rt-thead .rt-th,
.ReactTable .rt-thead .rt-td {
  padding: 12px 16px;
}

.ReactTable .rt-td {
  padding: 12px 32px;
  min-height: 65px;
}

.ReactTable .rt-td.whitespace-normal {
  white-space: normal;
}

.ReactTable .rt-thead.-filters .rt-th {
  padding: 1rem 3.2rem 1.5rem;
}

.ReactTable .rt-thead.-header {
  box-shadow: none;
  background-color: #FFFFFF;
}

.ReactTable.with-filters .rt-thead.-header {
  background-color: rgba(0, 0, 0, 0.03);
}

.ReactTable .rt-thead .rt-resizable-header-content {
  padding: 1rem 1.4rem;
  font-weight: 600;
}

.ReactTable .rt-thead .rt-resizable-header .rt-resizable-header-content {
  justify-content: flex-start;
}

.ReactTable .rt-td .table-cell-box p {
  text-align: left;
}

.ReactTable .rt-tbody .rt-tr:hover,
.ReactTable .rt-tbody .rt-tr.-even:hover,
.ReactTable .rt-tbody .rt-tr.-odd:hover {
  background-color: #E9ECEF!important;
}

.ReactTable .rt-tbody .rt-tr.-odd {
  background-color: #F8F9FA!important;
}

.ReactTable .rt-tbody .rt-tr.-even {
  background-color: #FFFFFF!important;
}
